import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { computed, ref, onBeforeMount, reactive } from 'vue';
import { useRouter } from 'vue-router';

// Components - Import
import { BaseContainer, BaseColumn, BaseGrid } from '@/components/primitives';
import { BaseButton, BaseSpacer } from '@/components/partials';
import { VerticalTable } from '@/components/containers';

// Constants - Import
import { StockStatus } from '@/constants/statuses/orderLineStatuses';
import ShippingAddonNames from '@/constants/shippingAddons/shippingAddonNames';

// Types - Import
import type { ComputedRef, Ref } from 'vue';
import type { TableRow } from '@/types/table';
import type { ProductItem } from '@/types/product';

// @use - Import
import useOrder from '@/@use/order';
import useOrderLines from '@/@use/orderLines';
import store from '@/store';
import OrderProcessTypes from '@/constants/orderPriority/orderProcessTypes';
import { isGiftResource } from '@/helpers/outgoingOrder';
import { OutgoingItemMode } from '@/store/types/outgoingOrder';

// @use - Envoke

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

/*------------------------------
- Vue
-- Import
- Components
-- Import
- Constants
-- Import
- Types
-- Import
--  Local
- @use
-- Import
-- Envoke
- Form data
- Table data
-------------------------------*/

// Vue - Import
const {
  item,
  deliveryMethod,
  isNewCustomer,
  userReference,
  parcels,
  oversized,
  deliveryLabelFailed,
  itemIsPacked,
  giftCode,
} = useOrder();
const router = useRouter();
const { products, productCount } = useOrderLines();

onBeforeMount(() => {
  store.dispatch('outgoingOrder/setDeliveryLabelFailed', false);
  if (!item.value?.id) {
    store.dispatch('outgoingOrder/getAssignedItem', OrderProcessTypes.PACKING);
  }
});

const isLoading: ComputedRef<boolean> = computed(() => store.getters['outgoingOrder/getStoreLoading']);
const giftLabelAcknowledged: ComputedRef<boolean> = computed(
  () => store.state.outgoingOrder.giftLabelWarningAckownledged
);

const onSubmitForPacking = async (): Promise<void> => {
  await store.dispatch('outgoingOrder/completeOutgoingProcess', { type: OrderProcessTypes.PACKING });
};

const onSubmitForPackingWithoutPrintingLabel = async (): Promise<void> => {
  await store.dispatch('outgoingOrder/completeOutgoingProcess', { type: OrderProcessTypes.PACKING });
  await router.push({ name: 'Packing' });
};

// Table data
const isTableDateLoaded: Ref<boolean> = ref(true);
const outOfStockProductsCount: ComputedRef<number> = computed(
  () => products.value?.filter((product: ProductItem) => product.status !== StockStatus.PACKED).length
);
const completedPicksDetail: ComputedRef<string> = computed(
  () => `(${outOfStockProductsCount?.value} with issues)`
);
const tableRows: ComputedRef<TableRow[]> = computed(() => {
  const checklist = item.value?.isGift
    ? [
        {
          content: 'Tape',
          color: 'default',
          type: 'list',
        },
        {
          content: 'Welcome note',
          color: 'default',
          type: 'list',
        },
        {
          content: 'Returns label with reference',
          color: 'default',
          type: 'list',
        },
        {
          content: 'Gift card: with gift card code written',
          color: 'default',
          type: 'list',
        },
        {
          content: 'Check knifes have been removed',
          color: 'danger',
          type: 'list',
        },
      ]
    : [
        {
          content: 'Tape',
          color: 'default',
          type: 'list',
        },
        {
          content: `Returns label with reference`,
          color: 'default',
          type: 'list',
        },
        {
          content: 'Check knifes have been removed',
          color: 'danger',
          type: 'list',
        },
      ];

  if (isGiftResource(item.value)) {
    return [
      {
        title: {
          content: 'Delivery',
        },
        columns: [
          {
            content: deliveryMethod.value,
            color: deliveryMethod.value === ShippingAddonNames.EXPRESS_DELIVERY ? 'danger' : 'default',
          },
        ],
      },
      {
        title: {
          content: 'Gift card code',
        },
        columns: [
          {
            content: giftCode.value,
            color: 'warning',
          },
        ],
      },
      {
        title: {
          content: 'Gift checklist',
        },
        columns: [
          {
            content: 'Welcome note',
            color: 'default',
            type: 'list',
          },
          {
            content: 'Gift card: with gift card code',
            color: 'default',
            type: 'list',
          },
          {
            content: 'Email printout',
            color: 'default',
            type: 'list',
          },
        ],
      },
    ];
  }

  return [
    {
      title: {
        content: 'Reference',
      },
      columns: [
        {
          content: item.value?.isGift ? item.value?.reference : userReference.value,
          color: 'default',
        },
      ],
    },
    {
      title: {
        content: 'Delivery',
      },
      columns: [
        {
          content: deliveryMethod.value,
          color: deliveryMethod.value === ShippingAddonNames.EXPRESS_DELIVERY ? 'danger' : 'default',
        },
      ],
    },
    {
      title: {
        content: 'Products',
      },
      columns: [
        {
          content:
            outOfStockProductsCount.value > 0
              ? `${productCount.value} ${completedPicksDetail.value}`
              : productCount.value,
          color: outOfStockProductsCount.value > 0 ? 'danger' : 'default',
        },
      ],
    },
    {
      title: {
        content: 'No. boxes',
      },
      columns: [
        {
          content: parcels.value,
          color: 'default',
        },
      ],
    },
    {
      title: {
        content: 'DPD',
      },
      columns: [
        {
          content: oversized.value ? 'Yes' : 'No',
          color: 'default',
        },
      ],
    },
    {
      title: {
        content: 'New customer',
      },
      columns: [
        {
          content: isNewCustomer.value,
          color: isNewCustomer.value === 'Yes' ? 'danger' : 'default',
        },
      ],
    },
    {
      title: {
        content: 'Gift card code',
      },
      columns: [
        {
          content: giftCode.value,
          color: 'warning',
        },
      ],
    },
    {
      title: {
        content: 'Pack checklist',
      },
      columns: checklist,
    },
  ];
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(BaseContainer), null, {
    default: _withCtx(() => [
      _createVNode(_unref(BaseGrid), null, {
        default: _withCtx(() => [
          _createVNode(_unref(BaseColumn), null, {
            default: _withCtx(() => [
              _createVNode(_unref(VerticalTable), {
                class: _normalizeClass({ 'is-complete': _unref(itemIsPacked) }),
                rows: tableRows.value,
                "is-loaded": !isLoading.value
              }, null, 8, ["class", "rows", "is-loaded"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_unref(BaseSpacer)),
      _createVNode(_unref(BaseGrid), null, {
        default: _withCtx(() => [
          _createVNode(_unref(BaseColumn), null, {
            default: _withCtx(() => [
              (_unref(isGiftResource)(_unref(item)) && !giftLabelAcknowledged.value && !_unref(itemIsPacked))
                ? (_openBlock(), _createBlock(_unref(BaseButton), {
                    key: 0,
                    "button-disabled": isLoading.value,
                    onClick: onSubmitForPacking,
                    color: "success"
                  }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode(" Complete pack ")
                    ])),
                    _: 1
                  }, 8, ["button-disabled"]))
                : (_unref(itemIsPacked))
                  ? (_openBlock(), _createBlock(_unref(BaseButton), {
                      key: 1,
                      "button-disabled": isLoading.value,
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push({ name: 'PackingPrintLabel' }))),
                      color: "success"
                    }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode(" Continue to printing label ")
                      ])),
                      _: 1
                    }, 8, ["button-disabled"]))
                  : (_unref(isGiftResource)(_unref(item)) && giftLabelAcknowledged.value && !_unref(itemIsPacked))
                    ? (_openBlock(), _createBlock(_unref(BaseButton), {
                        key: 2,
                        "button-disabled": isLoading.value,
                        onClick: onSubmitForPacking,
                        color: "success"
                      }, {
                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                          _createTextVNode(" Acknowledge & complete pack ")
                        ])),
                        _: 1
                      }, 8, ["button-disabled"]))
                    : (_unref(deliveryLabelFailed) === false && !_unref(itemIsPacked))
                      ? (_openBlock(), _createBlock(_unref(BaseButton), {
                          key: 3,
                          "button-disabled": isLoading.value,
                          onClick: onSubmitForPacking,
                          color: "success"
                        }, {
                          default: _withCtx(() => _cache[4] || (_cache[4] = [
                            _createTextVNode(" Create packing label ")
                          ])),
                          _: 1
                        }, 8, ["button-disabled"]))
                      : (_openBlock(), _createBlock(_unref(BaseButton), {
                          key: 4,
                          "button-disabled": isLoading.value,
                          onClick: onSubmitForPackingWithoutPrintingLabel,
                          color: "success"
                        }, {
                          default: _withCtx(() => _cache[5] || (_cache[5] = [
                            _createTextVNode(" Complete order without printing label ")
                          ])),
                          _: 1
                        }, 8, ["button-disabled"]))
            ]),
            _: 1
          }),
          (!_unref(itemIsPacked))
            ? (_openBlock(), _createBlock(_unref(BaseColumn), { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_unref(BaseButton), { url: "/packing/products" }, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode("Back to Pack List")
                    ])),
                    _: 1
                  }),
                  _createVNode(_unref(BaseSpacer), { size: "4" })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})